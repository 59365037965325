import { differenceInMinutes, formatDistanceToNow } from 'date-fns';

export const shortenAddress = (address?: string): string => {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const TOKEN_COLORS = {
  rETH: {
    borderColor: 'rgb(248,202,116)',
    backgroundColor: 'rgb(238,118,83)',
  },
  ETH: { borderColor: 'rgb(243,169,254)', backgroundColor: 'rgb(157,111,174)' },
  stETH: {
    borderColor: 'rgb(55,154,250)',
    backgroundColor: 'rgb(135,194,252)',
  },
  cbETH: { borderColor: 'rgb(0,83,248)', backgroundColor: 'rgb(94,144,250)' },
};

const calculateCompactTime = (futureDate: Date): string => {
  const totalMinutes = differenceInMinutes(futureDate, new Date());

  if (totalMinutes < 60) {
    return `${totalMinutes}m`;
  } else if (totalMinutes < 1440) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  } else {
    const days = Math.floor(totalMinutes / 1440);
    const remainingMinutes = totalMinutes % 1440;
    const hours = Math.floor(remainingMinutes / 60);
    return `${days}d ${hours}h`;
  }
};

export type TimeToBlockFormat = 'relative' | 'compact' | 'date';
export type TimeToBlockReturn<T extends TimeToBlockFormat> = T extends 'date'
  ? Date
  : string | number;

export const timeToBlock = <T extends TimeToBlockFormat = 'relative'>(
  currentBlock: bigint | number,
  targetBlock: bigint | number,
  format?: T,
): TimeToBlockReturn<T> => {
  const blocksLeft = Number(targetBlock) - Number(currentBlock);
  const remainingTimeMs = blocksLeft * 12000;
  try {
    const date = new Date(Date.now() + remainingTimeMs);
    if (format === 'date') return date as TimeToBlockReturn<T>;
    if (format === 'compact') {
      return calculateCompactTime(date) as TimeToBlockReturn<T>;
    }
    return formatDistanceToNow(date) as TimeToBlockReturn<T>;
  } catch (e) {
    return 0 as TimeToBlockReturn<T>;
  }
};
