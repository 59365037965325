'use client';

import './globals.css';

import { Analytics } from '@vercel/analytics/react';
import Script from 'next/script';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Footer from 'components/Layout/Footer';
import Header from 'components/Layout/Header';
import WarningBanner from 'components/WarningBanner';

import { ABCRepro, ibmPlexMono } from 'fonts/localFont';

import { api } from '../utils/api';
import { Providers } from './providers';

import { env } from '@env';

interface ClientProps {
  children: React.ReactNode;
}

function Client({ children }: ClientProps) {
  return (
    <html lang="en">
      <body
        id="root"
        className={`${ABCRepro.className} ${ABCRepro.variable} ${ibmPlexMono.variable} bg-lightblue-100 text-blue-700`}
      >
        <Providers>
          <WarningBanner />
          <Header />
          <div className="container mx-auto mb-24 mt-4 px-2 sm:mt-6">{children}</div>
          <Footer />
          <ReactQueryDevtools initialIsOpen={false} />
        </Providers>
      </body>
      {env.NEXT_PUBLIC_VERCEL_ENV === 'production' && env.NEXT_PUBLIC_GA_TAG ? (
        <>
          <Analytics />
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${env.NEXT_PUBLIC_GA_TAG}`}
            strategy="afterInteractive"
          />
          <Script id="ga-script-tag" strategy="afterInteractive">
            {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${env.NEXT_PUBLIC_GA_TAG}');`}
          </Script>
        </>
      ) : null}
    </html>
  );
}

export default api.withTRPC(Client) as React.FC<ClientProps>;
