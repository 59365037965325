'use client';

import { useEffect, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { TextV2 } from '@layr-labs/eigen-kit/react';

import NavLink from 'components/Layout/NavLink';

import useStatus from '@/hooks/useStatus';

const BANNER_DISMISSAL_KEY = 'el-isBannerDismissed';

export default function WarningBanner() {
  const [isWarningBannerOpen, setIsWarningBannerOpen] = useState<boolean>(false);

  const { data: edgeConfig } = useStatus();

  useEffect(() => {
    if (edgeConfig.statusCount > 0) {
      setIsWarningBannerOpen(true);
    }
  }, [edgeConfig.statusCount]);

  const [bannerDismissed, setBannerDismissed] = useState<boolean>(false);
  useEffect(() => {
    const isBannerDismissed = localStorage.getItem(BANNER_DISMISSAL_KEY);

    const bannerDismissalTime = Number(isBannerDismissed);
    const currentTime = Date.now();
    const timeDiff = currentTime - bannerDismissalTime;
    const isBannerDismissalTimeLessThanADay = timeDiff < 86400000;
    if (isBannerDismissalTimeLessThanADay) {
      setBannerDismissed(true);
    }
  }, []);

  return !bannerDismissed ? (
    <div
      className={`bg-info flex flex-row items-center justify-between p-2 ${
        !isWarningBannerOpen && 'hidden'
      }`}
    >
      <button
        onClick={() => {
          setIsWarningBannerOpen(false);
          localStorage.setItem(BANNER_DISMISSAL_KEY, Date.now().toString());
        }}
        className="text-secondaryBg absolute h-4 w-4"
      >
        <XMarkIcon className="h-4 w-4" />
      </button>
      <div className="text-secondaryBg mx-auto flex flex-row items-center gap-1">
        <TextV2 className="ml-6 text-TextXS sm:text-TextM" intent="TextXS">
          {edgeConfig.bannerMessage}
        </TextV2>
        <NavLink
          href="/status"
          className="text-secondaryBg hover:text-interactivePrimaryHover text-TextXS underline sm:text-TextM"
        >
          Check Status
        </NavLink>
      </div>
    </div>
  ) : null;
}
