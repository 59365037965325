'use client';

import { ReactNode, useEffect, useState } from 'react';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import {
  NotificationToastProvider,
  SheetProvider,
  ToastProvider,
} from '@layr-labs/eigen-kit/react';

import { DatadogRumProvider, DatadogUserContext } from 'components/DatadogRumProvider';
import TOSProvider from 'components/TOSProvider';
import TransactionToastProvider from 'components/TransactionToastProvider';

import { Web3WagmiProvider } from 'chain/Web3Provider';

export function Providers({ children }: { children: ReactNode }) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  return (
    <Web3WagmiProvider>
      <DatadogRumProvider />
      <SheetProvider>
        <ToastProvider>
          <TransactionToastProvider />
          <RadixTooltip.Provider>
            <NotificationToastProvider>
              {mounted && (
                <>
                  <DatadogUserContext />
                  <TOSProvider>{children}</TOSProvider>
                </>
              )}
            </NotificationToastProvider>
          </RadixTooltip.Provider>
        </ToastProvider>
      </SheetProvider>
    </Web3WagmiProvider>
  );
}
