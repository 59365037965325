import { useEffect, useState } from 'react';

import useAccount from '@/hooks/useAccount';

import { api } from '@/utils/api';

import tosHashes from '../config/tosHashes.json';

interface signedTerms {
  hash: string;
  signature: string;
  timestamp: number;
}

interface termsSignature {
  address: string;
  signedTerms: signedTerms[];
}

interface userAgreement {
  publicKey: string;
  signedTerms: signedTerms;
}

interface CachedUserAgreement extends userAgreement {
  userAddress: string;
}

const cacheSignature = (userAgreement: CachedUserAgreement) => {
  const userTerms = localStorage.getItem('userTerms');
  if (userTerms) {
    const terms = JSON.parse(userTerms);
    terms.push(userAgreement);
    localStorage.setItem('userTerms', JSON.stringify(terms));
    return;
  } else {
    localStorage.setItem('userTerms', JSON.stringify([userAgreement]));
  }
};

const useTOS = () => {
  const { connectedWalletAddress, addressAccess, isConnected } = useAccount();
  const [access, setAccess] = useState(false);
  const [isTermsSigned, setIsTermsSigned] = useState(true);
  const [termsWriteRetry, setTermsWriteRetry] = useState(false);

  const userTerms = api.userAgreement.getUserAgreement.useQuery<termsSignature>(
    { address: connectedWalletAddress },
    { enabled: !!isConnected && !!connectedWalletAddress },
  );

  const storeUserTerms = api.userAgreement.saveUserAgreement.useMutation();

  const checkSignature = async (): Promise<boolean> => {
    const verifyLocalStore = () => {
      const userTerms = localStorage.getItem('userTerms');
      if (userTerms) {
        const terms = JSON.parse(userTerms);
        const currentUsersTerms = terms.find((term) => term.userAddress === connectedWalletAddress);
        if (currentUsersTerms) {
          setTermsWriteRetry(true);
          return currentUsersTerms.signedTerms.hash === tosHashes[tosHashes.length - 1].totalHash;
        }
        return false;
      }
      return false;
    };

    const { data: userSignatureData, isSuccess } = await userTerms.refetch();
    if (userSignatureData && isSuccess) {
      if (!userSignatureData.signedTerms || userSignatureData.signedTerms.length === 0) {
        return verifyLocalStore();
      }

      const isTermHashLatest = userSignatureData.signedTerms.find(
        (termHash) => termHash.hash === tosHashes[tosHashes.length - 1].totalHash,
      );

      return !!isTermHashLatest;
    } else {
      // check if user has signed terms in local storage
      return verifyLocalStore();
    }
  };

  useEffect(() => {
    if (isConnected && addressAccess.data === 'deny') {
      setAccess(false);
      return;
    }

    setAccess(true);

    if (isConnected) {
      checkSignature().then((res) => {
        setIsTermsSigned(res);
      });
    }
    // `checkSignature` created each render so we don't need to add it to the
    // dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWalletAddress, isConnected, addressAccess.data]);

  useEffect(() => {
    if (termsWriteRetry) {
      const userTerms = localStorage.getItem('userTerms');
      const terms = JSON.parse(userTerms!);
      const currentUsersTerms = terms.find((term) => term.userAddress === connectedWalletAddress);
      if (
        currentUsersTerms &&
        currentUsersTerms.signedTerms.hash === tosHashes[tosHashes.length - 1].totalHash
      ) {
        storeUserTerms.mutate(currentUsersTerms);
      }

      setTermsWriteRetry(false);
    }
    // `storeUserTerms` is a mutation that doesn't need to be in the
    // dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsWriteRetry, connectedWalletAddress]);

  return {
    access,
    isTermsSigned,
    setIsTermsSigned,
    cacheSignature,
    storeUserTerms,
  };
};

export default useTOS;
