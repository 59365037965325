import { useEffect, useState } from 'react';
import Link from 'next/link';

import * as Dialog from '@radix-ui/react-dialog';

import { ButtonV2, ComponentSize, TextV2 } from '@layr-labs/eigen-kit/react';
import {
  getLocalStorageItemWithExpiration,
  setLocalStorageItemWithExpiration,
} from '@layr-labs/eigen-kit/util';

const CTA_KEY = 'el-isRestrictedModalDismissed';

function RestrictedAccessModal({ addressIsRestricted, address }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!address) {
      return;
    }
    const bannerDismissed = getLocalStorageItemWithExpiration(`${CTA_KEY}-${address}`);

    if (bannerDismissed !== 'true' && addressIsRestricted) {
      setOpen(true);
    }
  }, [addressIsRestricted, address]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 flex h-screen w-screen items-center justify-center bg-white/70" />
        <Dialog.Content
          data-cypress="RestrictAccessModal"
          className="fixed left-1/2 top-1/3 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center rounded-2xl bg-lightblue-200 px-4 pb-6 pt-4 shadow-2xl outline-none sm:mt-20 sm:h-auto sm:w-[400px]"
        >
          <TextV2 intent="DisplayXS" className="text-center">
            ACCESS RESTRICTED
          </TextV2>
          <TextV2 className="mt-2 overflow-y-scroll text-justify">
            Your wallet has been flagged as being associated with certain high risk activity or
            jurisdictions. You may unstake assets, but all other functionality of the application
            has been disabled. Please visit our {'  '}
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://docs.eigenlayer.xyz/eigenlayer/legal/terms-of-service"
              className="text-blue-700 underline underline-offset-4 hover:text-blue-300"
            >
              Terms of Service.
            </Link>{' '}
            for more information. If you feel your wallet has been denied access incorrectly, please
            reach out to our help channel.
          </TextV2>
          {/* to prevent the first button from being focused on dialog open */}
          <button className="h-0 w-0 outline-none" />
          <ButtonV2
            onClick={() => {
              setOpen(false);
              setLocalStorageItemWithExpiration(CTA_KEY, 'true', 1000 * 60 * 60 * 24);
            }}
            size={ComponentSize.SM}
            intent="secondary"
            className="w-full"
          >
            Continue
          </ButtonV2>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default RestrictedAccessModal;
