import Image from 'next/image';
import { usePathname } from 'next/navigation';

import { ButtonV2, ComponentSize } from '@layr-labs/eigen-kit/react';
import { cn } from '@layr-labs/eigen-kit/util';

import ConnectWallet from 'components/ConnectWallet';

import NavLink from './NavLink';

export const LINKS = [
  {
    label: 'Dashboard',
    href: '/',
    activeRoutes: ['/'],
  },
  {
    label: 'Restake',
    href: '/restake',
    activeRoutes: ['/restake'],
  },
  {
    label: 'Operator',
    href: '/operator',
    activeRoutes: ['/operator'],
  },
  {
    label: 'AVS',
    href: '/avs',
    activeRoutes: ['/avs/eigenda', '/avs'],
  },
];

export function HeaderLinks({ pathname }: { pathname: string | null }) {
  return (
    <>
      {LINKS.map(({ label, href, activeRoutes }) => {
        const isActive = activeRoutes.some((route) => {
          if (route === '/') return pathname === route;
          return pathname?.startsWith(route);
        });
        return (
          <ButtonV2
            intent="nav"
            key={label}
            className={cn('px-2 py-0', isActive && 'border-blue-800')}
            size={ComponentSize.XS}
          >
            <NavLink href={href}>{label}</NavLink>
          </ButtonV2>
        );
      })}
    </>
  );
}

function Header() {
  const pathname = usePathname();

  return (
    <>
      <header className="flex h-14 items-center justify-between border-b bg-white px-2 shadow-sm sm:px-4 lg:h-16">
        <div className="mr-4 flex items-center gap-3">
          <NavLink href="/">
            <div className="relative block h-12 w-12 lg:h-16">
              <Image src="/logo/markLightA.svg" alt="eigenlayer logo" fill />
            </div>
          </NavLink>
        </div>
        <div className="mr-auto hidden w-full max-w-sm items-center justify-between gap-4 sm:flex">
          <HeaderLinks pathname={pathname} />
        </div>
        <div className="flex items-center gap-4">
          <ConnectWallet />
        </div>
      </header>

      {/*Mobile nav links*/}

      <div className="mt-2 flex w-full items-center justify-between gap-2 px-0.5 text-center sm:hidden">
        <HeaderLinks pathname={pathname} />
      </div>
    </>
  );
}

export default Header;
