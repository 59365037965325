import React from 'react';

import { Address } from 'viem';
import { create } from 'zustand';

export interface Transaction {
  id: string;
  hash?: Address;
  status: 'pending' | 'success' | 'error';
  successMsg: React.ReactNode;
  errorMsg: React.ReactNode;
  loadingMsg: React.ReactNode;
  toastId?: string;
  disableToast?: boolean;
}

interface TransactionStore {
  transactions: Transaction[];
  addTransaction: (transaction: Transaction) => void;
  removeTransaction: (id: string) => void;
  updateTransaction: (id: string, updates: Partial<Transaction>) => void;
}

const useTransactionStore = create<TransactionStore>((set) => ({
  transactions: [],
  addTransaction: (transaction) =>
    set((state) => ({
      transactions: [...state.transactions, transaction],
    })),
  removeTransaction: (id) =>
    set((state) => ({
      transactions: state.transactions.filter((txn) => txn.id !== id),
    })),
  updateTransaction: (id, updates) =>
    set((state) => ({
      transactions: state.transactions.map((txn) => (txn.id === id ? { ...txn, ...updates } : txn)),
    })),
}));

export default useTransactionStore;
