import { useDisconnect, useEnsName } from 'wagmi';

import { ConnectWallet as ConnectWalletComponent, useWeb3Modal } from '@layr-labs/eigen-kit/react';

import useAccount from '@/hooks/useAccount';

import { env } from '@env';

function ConnectWallet({
  intent = 'secondary',
  disconnectedClassName,
  connectedClassName,
}: {
  intent?: 'primary' | 'secondary';
  disconnectedClassName?: string;
  connectedClassName?: string;
}) {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { data: ensName } = useEnsName({
    address,
    query: {
      enabled: env.NEXT_PUBLIC_CHAIN_ID !== '17000',
    },
  });
  const { disconnect } = useDisconnect({});

  return (
    <ConnectWalletComponent
      open={open}
      isConnected={isConnected}
      address={address}
      ensName={ensName}
      disconnect={disconnect}
      blockExplorerURL={env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}
      intent={intent}
      disconnectedClassName={disconnectedClassName}
      connectedClassName={connectedClassName}
    />
  );
}

export default ConnectWallet;
