export default function Privacy() {
  return (
    <div className="privacy-body">
      <p className="c39">
        <h1 className="c15 c4">EIGENLAYER PRIVACY POLICY</h1>
      </p>
      <p className="c20">
        <span className="c13 c8 c4" />
      </p>
      <p className="c31">
        <span className="bold">Last Revised on April 14, 2023</span>
      </p>
      <p className="c20">
        <span className="c2" />
      </p>
      <div className="terms-list-item">
        This Privacy Policy for Layr Labs, Inc. (“Company”, “we”, “us” “our”) describes how we
        collect, use and disclose information about users of the Company’s website (eigenlayer.xyz),
        and any related services, tools and features, including the EigenLayer service
        (collectively, the “Services”). For the purposes of this Privacy Policy, “you” and “your”
        means you as the user of the Services.
      </div>
      <p className="c6">
        <span className="c2" />
      </p>
      <div className="terms-list-item">
        <span className="c2">
          Please read this Privacy Policy carefully. &nbsp;By using, accessing, or downloading any
          of the Services, you agree to the collection, use, and disclosure of your information as
          described in this Privacy Policy. &nbsp;If you do not agree to this Privacy Policy, please
          do not use, access or download any of the Services.
        </span>
      </div>
      <h2 style={{ display: 'inline' }}>
        <span className="c4 c9">UPDATING THIS PRIVACY POLICY</span>
      </h2>
      <div className="terms-list-item">
        We may modify this Privacy Policy from time to time in which case we will update the “Last
        Revised” date at the top of this Privacy Policy. If we make material changes to the way in
        which we use information we collect, we will use reasonable efforts to notify you (such as
        by emailing you at the last email address you provided us, by posting notice of such changes
        on the Services, or by other means consistent with applicable law) and will take additional
        steps as required by applicable law. If you do not agree to any updates to this Privacy
        Policy please do not access or continue to use the Services.
      </div>
      <h2 style={{ display: 'inline' }}>
        <span className="c4">COMPANY’S COLLECTION AND USE OF INFORMATION</span>
      </h2>
      <div>
        <div className="terms-list-item">
          <p>
            When you access or use the Services, we may collect certain categories of information
            about you from a variety of sources, which comprises:
          </p>

          <ul>
            <li>
              The following information about you: name, email address, and Discord Tag. We collect
              your email address and Discord Tag in order to communicate with you through the
              Services and through third party platforms, such as Discord.
            </li>

            <li>
              The following third-party wallet (`&quot;`Wallet`&quot;`) information: public wallet
              address and token holdings. We collect third-party Wallet information in order to
              facilitate your use of the Services.
            </li>

            <li>
              Any other information you choose to include in communications with us, for example,
              when sending a message through the Services.
            </li>
          </ul>

          <p>
            We also automatically collect certain information about your interaction with the
            Services (`&quot;Usage Data`&quot;). To do this, we may use cookies, web beacons/clear
            gifs and other geolocation tracking technologies (`&quot;Tracking Technologies`&quot;).
            Usage Data comprises of:{' '}
          </p>

          <ul>
            <li>
              Device information (e.g., unique device identifier, device type, IP address, operating
              system)
            </li>

            <li>Browser information (e.g., browser type)</li>

            <li>Location information (e.g., approximate geolocation)</li>

            <li>
              Other information regarding your interaction with the Services (e.g., log data, date
              and time stamps, clickstream data)
            </li>
          </ul>

          <p>
            We use Usage Data to tailor features and content to you and to run analytics and better
            understand user interaction with the Services. For more information on how we use
            Tracking Technologies and your choices, see the section below, Cookies and Other
            Tracking Technologies.
          </p>

          <p>
            In addition to the foregoing, we may use any of the above information to comply with any
            applicable legal obligations, to enforce any applicable terms of service, and to protect
            or defend the Services, our rights, and the rights of our users or others.
          </p>
        </div>
      </div>
      <h2 style={{ display: 'inline' }}>
        <span className="c9 c4">HOW THE COMPANY SHARES YOUR INFORMATION</span>
      </h2>
      <div className="terms-list-item">
        <p>
          In certain circumstances, the Company may share your information with third parties for
          legitimate purposes subject to this Privacy Policy. Such circumstances comprise of:
        </p>

        <ul>
          <li>
            With vendors or other service providers, such as
            <ul>
              <li>Blockchain analysis service providers, including Chainanalysis</li>
              <li>Data analytics vendors, including Google Analytics</li>
            </ul>
          </li>

          <li>
            To comply with applicable law or any obligations thereunder, including cooperation with
            law enforcement, judicial orders, and regulatory inquiries
          </li>

          <li>
            In connection with an asset sale, merger, bankruptcy, or other business transaction
          </li>

          <li>To enforce any applicable terms of service</li>

          <li>To ensure the safety and security of the Company and/or its users</li>

          <li>
            When you request us to share certain information with third parties, such as through
            your use of login integrations
          </li>

          <li>With professional advisors, such as auditors, law firms, or accounting firms</li>
        </ul>
      </div>
      <h2 style={{ display: 'inline' }}>
        <span className="c9 c4">COOKIES AND OTHER TRACKING TECHNOLOGIES</span>
      </h2>
      <div className="terms-list-item">
        <span className="bold">Do Not Track Signals</span>

        <p>
          Your browser settings may allow you to transmit a `&quot;`Do Not Track`&quot;` signal when
          you visit various websites. Like many websites, our website is not designed to respond to
          `&quot;`Do Not Track`&quot;` signals received from browsers. To learn more about
          `&quot;`Do Not Track`&quot;` signals, you can visit www.allaboutdnt.com.
        </p>

        <h2>Cookies and Other Tracking Technologies</h2>

        <p>
          Most browsers accept cookies automatically, but you may be able to control the way in
          which your devices permit the use of cookies, web beacons/clear gifs, other geolocation
          tracking technologies. If you so choose, you may block or delete our cookies from your
          browser; however, blocking or deleting cookies may cause some of the Services, including
          any portal features and general functionality, to work incorrectly. If you have questions
          regarding the specific information about you that we process or retain, as well as your
          choices regarding our collection and use practices, please contact us using the
          information listed below.
        </p>

        <p>
          To opt out of tracking by Google Analytics, click{' '}
          <a className="clickhere" href="https://tools.google.com/dlpage/gaoptout" target="_blank">
            {' '}
            here
          </a>
          .
        </p>

        <p>
          Your browser settings may allow you to transmit a `&quot;Do Not Track`&quot; signal when
          you visit various websites. Like many websites, our website is not designed to respond to
          `&quot;Do Not Track`&quot; signals received from browsers. To learn more about `&quot;Do
          Not Track`&quot; signals, you can visit www.allaboutdnt.com.
        </p>
      </div>
      <h2 id="h.gjdgxs" style={{ display: 'inline' }}>
        <span className="c9 c4">SOCIAL NETWORKS AND OTHER THIRD PARTY WEBSITES AND LINKS</span>
      </h2>
      <div className="terms-list-item">
        We may provide links to websites or other online platforms operated by third parties,
        including third-party social networking platforms, such as Twitter, Discord, or Medium,
        operated by third parties (such platforms are “Social Networks”). If you follow links to
        sites not affiliated or controlled by us, you should review their privacy and security
        policies and other terms and conditions. We do not guarantee and are not responsible for the
        privacy or security of these sites, including the accuracy, completeness, or reliability of
        information found on these sites. Information you provide on public or semi-public venues,
        including information you share or post on Social Networks, may also be accessible or
        viewable by other users of the Services and/or users of those third-party online platforms
        without limitation as to its use by us or by a third party. Our inclusion of such links does
        not, by itself, imply any endorsement of the content on such platforms or of their owners or
        operators, except as disclosed on the Services.
      </div>
      <h2 style={{ display: 'inline' }}>
        <span className="c9 c4">THIRD PARTY WALLET EXTENSIONS</span>
      </h2>
      <div className="terms-list-item">
        Certain transactions conducted via our Services, will require you to connect a Wallet to the
        Services. By using such Wallet to conduct such transactions via the Services, you agree that
        your interactions with such third party Wallets are governed by the privacy policy for the
        applicable Wallet. We expressly disclaim any and all liability for actions arising from your
        use of third party Wallets, including but without limitation, to actions relating to the use
        and/or disclosure of personal information by such third party Wallets. &nbsp;
      </div>
      <h2 style={{ display: 'inline' }}>
        <span className="c9 c30">CHILDREN’S PRIVACY</span>
      </h2>
      <div className="terms-list-item">
        Children under the age of 18 are not permitted to use the Services, and we do not seek or
        knowingly collect any personal information about children under 13 years of age. If we
        become aware that we have unknowingly collected information about a child under 13 years of
        age, we will make commercially reasonable efforts to delete such information from our
        database.
        <p className="c24">
          <span className="c2">
            If you are the parent or guardian of a child under 13 years of age who has provided us
            with their personal information, you may contact us using the below information to
            request that it be deleted.
          </span>
        </p>
      </div>

      <h2 style={{ display: 'inline' }}>
        <span className="c9 c4">DATA SECURITY</span>
      </h2>
      <div className="terms-list-item">
        Please be aware that, despite our reasonable efforts to protect your information, no
        security measures are perfect or impenetrable, and we cannot guarantee “perfect security.”
        Please further note that any information you send to us electronically, while using the
        Services or otherwise interacting with us, may not be secure while in transit. We recommend
        that you do not use unsecure channels to communicate sensitive or confidential information
        to us.{' '}
      </div>

      <h2 style={{ display: 'inline' }}>
        <span className="c9 c4">HOW TO CONTACT US</span>
      </h2>
      <div className="terms-list-item">
        Should you have any questions about our privacy practices or this Privacy Policy, please
        email us at admin@layrlabs.org or contact us at 1100 NE Campus Pkwy, Seattle, WA 98105.
      </div>
    </div>
  );
}
