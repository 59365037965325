'use client';

import { useCallback, useEffect, useState } from 'react';

import { CurrencyDollarIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import * as Separator from '@radix-ui/react-separator';

import {
  EthIcon,
  InfoPopover,
  NumberDisplay,
  TextV2,
  TvlPieChart,
  ValueLoader,
} from '@layr-labs/eigen-kit/react';

import { useTokens } from '@/hooks/useTokens';
import { useTVLs } from '@/hooks/useTVL';

import { TOKEN_COLORS } from 'utils/display';

import { env } from '@env';

const TOTAL_ETH_SUBLABEL = 'Total ETH';

const isTestnet =
  env.NEXT_PUBLIC_CONTRACT_ENV === 'testnet-holesky' ||
  env.NEXT_PUBLIC_CONTRACT_ENV === 'preprod-holesky';

export default function Footer() {
  const [tvlMode, setTvlMode] = useState<'eth' | 'usd'>('eth');
  const isEthMode = tvlMode === 'eth';
  const [chartLabel, setChartLabel] = useState('');
  const [chartSubLabel, setChartSubLabel] = useState(TOTAL_ETH_SUBLABEL);

  const {
    data: { liquidRestakingTokens, nativeRestakingToken },
  } = useTokens();
  const data =
    liquidRestakingTokens && nativeRestakingToken
      ? [...liquidRestakingTokens, nativeRestakingToken]
          .filter(({ tvl, usd }) => tvl > 0 && usd > 0)
          .map(({ symbol, icon, tvl, usd }) => {
            return { symbol, icon, tvl, usd };
          })
      : [];

  const { isLoading: isTvlLoading, data: tvlData } = useTVLs(
    liquidRestakingTokens,
    nativeRestakingToken,
  );
  const { totalUSD, totalETH } = tvlData;

  const resetChart = useCallback(() => {
    setChartLabel(
      isEthMode
        ? totalETH.toLocaleString(undefined, { minimumFractionDigits: 4 })
        : totalUSD.toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          }),
    );
    setChartSubLabel(isEthMode ? TOTAL_ETH_SUBLABEL : 'Total in USD');
  }, [isEthMode, totalETH, totalUSD]);

  useEffect(() => {
    if (totalETH > 0 && totalUSD > 0) {
      resetChart();
    }
  }, [isEthMode, totalETH, totalUSD, resetChart]);

  const chartData = data.map(({ symbol, tvl, usd }) => ({
    label: symbol,
    value: tvl * usd,
    borderColor: TOKEN_COLORS[symbol]?.borderColor,
    backgroundColor: TOKEN_COLORS[symbol]?.backgroundColor,
    onMouseEnter: () => {
      setChartLabel(
        isEthMode
          ? tvl.toLocaleString(undefined, { minimumFractionDigits: 4 })
          : (tvl * usd).toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            }),
      );
      setChartSubLabel(symbol);
    },
    onMouseLeave: resetChart,
  }));

  const currentTotal = isEthMode ? totalETH : totalUSD;

  let labelSize = 14;
  if (currentTotal >= 1000000000000) {
    labelSize = 10;
  } else if (currentTotal >= 10000000000) {
    labelSize = 11;
  } else if (currentTotal >= 1000000000) {
    labelSize = 12;
  }

  return (
    <div className="fixed bottom-0 flex w-full justify-between bg-white px-4 py-2 shadow-2xl">
      <div className="flex items-center gap-1 sm:gap-2">
        <a
          href="https://docs.eigenlayer.xyz/eigenlayer/legal/terms-of-service"
          target="blank"
          rel="noreferrer"
        >
          <TextV2 className="inline hover:text-blue-700 sm:hidden">ToS</TextV2>
          <TextV2 className="hidden hover:text-blue-700 sm:inline">Terms of Service</TextV2>
        </a>
        <Separator.Root orientation="vertical" className="h-full w-0.5 bg-black/10" />
        <a
          href="https://docs.eigenlayer.xyz/overview/eigenlayer-privacy-policy"
          target="blank"
          rel="noreferrer"
        >
          <TextV2 className="inline hover:text-blue-700 sm:hidden">Privacy</TextV2>
          <TextV2 className="hidden hover:text-blue-700 sm:inline">Privacy Policy</TextV2>
        </a>
        {isTestnet && (
          <div className="ml-2 flex items-center gap-1 text-blue-200">
            <InfoPopover trigger={<ExclamationTriangleIcon className="h-4 w-4" />}>
              <TextV2>Testnet: some features may be limited or unstable</TextV2>
            </InfoPopover>

            <TextV2 className="hidden lg:block">
              Testnet: some features may be limited or unstable
            </TextV2>
            <TextV2 className="block lg:hidden">Testnet</TextV2>
          </div>
        )}
      </div>
      <InfoPopover
        contentClassName="bg-blue-200"
        trigger={
          <div className="ml-auto flex h-full items-center justify-end gap-2">
            <TextV2 className="hidden sm:inline">TVL</TextV2>
            <TextV2 intent="TextS" className="inline sm:hidden">
              TVL
            </TextV2>
            <Separator.Root orientation="vertical" className="h-full w-[1px] bg-black-100" />
            <TextV2 intent="TextL" className="hidden items-center sm:flex">
              {isTvlLoading ? (
                <ValueLoader intent="TextL" className="mx-2 w-28" />
              ) : isEthMode ? (
                <NumberDisplay value={totalETH} format="tokenAmount" className="mr-1 block" />
              ) : (
                <NumberDisplay value={totalUSD} format="usd" className="block" />
              )}{' '}
              {isEthMode && 'ETH'}
            </TextV2>
            <TextV2 intent="TextS" className="flex items-center sm:hidden">
              {isTvlLoading ? (
                <ValueLoader intent="TextL" font="ibmPlexMono" className="mx-2 w-28" />
              ) : isEthMode ? (
                <NumberDisplay value={totalETH} format="tokenAmount" className="mr-1 block" />
              ) : (
                <NumberDisplay value={totalUSD} format="usd" className="block" />
              )}{' '}
              {isEthMode && 'ETH'}
            </TextV2>
          </div>
        }
      >
        <div className="relative">
          <div
            className="border-borderBounds group right-2 top-2 ml-auto flex w-fit cursor-pointer flex-row rounded border border-blue-700 p-1"
            onClick={() => {
              setTvlMode(isEthMode ? 'usd' : 'eth');
            }}
          >
            <EthIcon
              className={`h-3.5 w-3.5 group-hover:text-blue-400 ${
                !isEthMode ? 'text-blue-400' : 'text-blue-700'
              }`}
            />
            <CurrencyDollarIcon
              className={`h-3.5 w-3.5 group-hover:text-blue-400 ${
                isEthMode ? 'text-blue-400' : 'text-blue-700'
              }`}
            />
          </div>
          <div className="h-[225px] w-[225px]">
            <TvlPieChart
              data={chartData}
              label={chartLabel}
              subLabel={chartSubLabel}
              labelSize={labelSize}
            />
          </div>
        </div>
      </InfoPopover>
    </div>
  );
}
