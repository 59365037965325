'use client';

import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import useAccount from '@/hooks/useAccount';

import { env } from '@env';

const isVercelProduction = env.NEXT_PUBLIC_VERCEL_ENV === 'production';

if (isVercelProduction) {
  datadogRum.init({
    applicationId: env.NEXT_PUBLIC_DD_RUM_APP_ID,
    clientToken: env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'restaking',
    env: env.NEXT_PUBLIC_DD_RUM_ENV,
    sessionSampleRate: env.NEXT_PUBLIC_DD_RUM_SAMPLE_RATE,
    sessionReplaySampleRate: env.NEXT_PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

export function DatadogRumProvider() {
  return null;
}

export function DatadogUserContext() {
  const { address } = useAccount();

  useEffect(() => {
    if (!isVercelProduction) {
      return;
    }

    if (address) {
      datadogRum?.setUser({
        address,
      });
    } else {
      datadogRum?.clearUser();
    }
  }, [address]);
  return null;
}
