'use client';

import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { readContracts } from 'wagmi/actions';

import { IDelegationManagerAbi, IStrategyManagerAbi } from '@layr-labs/eigen-kit/abi';

import { config } from 'chain/Web3Provider';
import { stakeConfig } from '@/config';
import { api } from '@/utils/api';

const useStatus = () => {
  const {
    data: contractStatus,
    isLoading: isContractStatusLoading,
    isError: isContractStatusError,
    isSuccess: isContractStatusSuccess,
  } = useQuery({
    queryKey: ['contract-status'],
    queryFn: () =>
      readContracts(config, {
        contracts: [
          {
            address: stakeConfig.strategyManagerAddress,
            abi: IStrategyManagerAbi,
            functionName: 'paused',
            args: [0],
          },
          {
            address: stakeConfig.strategyManagerAddress,
            abi: IStrategyManagerAbi,
            functionName: 'paused',
            args: [1],
          },
          {
            address: stakeConfig.delegationManagerAddress,
            abi: IDelegationManagerAbi,
            functionName: 'paused',
            args: [0],
          },
        ],
      }).then(([isDepositsPaused, isWithdrawalsPaused, isDelegationPaused]) => ({
        isDepositsPaused: Boolean(isDepositsPaused?.result),
        isWithdrawalsPaused: Boolean(isWithdrawalsPaused?.result),
        isDelegationPaused: Boolean(isDelegationPaused?.result),
      })),
    initialData: {
      isDepositsPaused: false,
      isWithdrawalsPaused: false,
      isDelegationPaused: false,
    },
  });

  const {
    data: edgeConfig,
    isLoading: isEdgeConfigLoading,
    isError: isEdgeConfigError,
    isSuccess: isEdgeConfigSuccess,
  } = api.edgeConfig.getEdgeConfig.useQuery(undefined, {
    initialData: {
      isMaintenance: false,
      isNativeMaintenance: false,
      isLSTMaintenance: false,
      isNativeDepositDisabled: false,
      isLSTDepositDisabled: false,
      isWithdrawalDisabled: false,
      isDelegationDisabled: false,
      isRewardsDisabled: false,
    },
  });

  const statusData = {
    functionStatuses: {
      lstDeposits: {
        type: 'LST Deposits',
        isAppPaused: edgeConfig.isLSTDepositDisabled,
        isContractPaused: contractStatus.isDepositsPaused,
        isDisabled: edgeConfig.isLSTDepositDisabled || contractStatus.isDepositsPaused,
        message: 'Liquid Restaking deposits on EigenLayer are temporarily paused.',
      },
      nativeDeposits: {
        type: 'Native Deposits',
        isAppPaused: edgeConfig.isNativeDepositDisabled,
        isContractPaused: false,
        isDisabled: edgeConfig.isNativeDepositDisabled,
        message: 'Native Restaking deposits on EigenLayer are temporarily paused.',
      },
      delegation: {
        type: 'Delegation',
        isAppPaused: edgeConfig.isDelegationDisabled,
        isContractPaused: contractStatus.isDelegationPaused,
        isDisabled: edgeConfig.isDelegationDisabled || contractStatus.isDelegationPaused,
        message: 'Delegation is temporarily paused.',
      },
      withdrawal: {
        type: 'Withdrawals',
        isAppPaused: edgeConfig.isWithdrawalDisabled,
        isContractPaused: contractStatus.isWithdrawalsPaused,
        isDisabled: edgeConfig.isWithdrawalDisabled || contractStatus.isWithdrawalsPaused,
        message: 'Withdrawals are temporarily paused.',
      },
      rewards: {
        type: 'Rewards',
        isAppPaused: edgeConfig.isRewardsDisabled,
        isContractPaused: false,
        isDisabled: edgeConfig.isRewardsDisabled,
        message:
          'The ability to view rewards is currently undergoing maintenance. Please check back later.',
      },
    },
    isNativeMaintenanceMode: edgeConfig.isNativeMaintenance,
    isLSTMaintenanceMode: edgeConfig.isLSTMaintenance,
    bannerMessage: '',
    statusCount: 0,
  };

  const status = Object.values(statusData.functionStatuses).filter((value) => value?.isDisabled);

  const bannerMessage = useMemo(() => {
    if (status.length > 1) {
      return 'Multiple functions are currently paused.';
    }
    if (status.length === 1) {
      return status[0].message;
    }
    return '';
  }, [status]);

  return {
    data: { ...statusData, statusCount: status.length, bannerMessage },
    isLoading: isContractStatusLoading || isEdgeConfigLoading,
    isError: isContractStatusError || isEdgeConfigError,
    isSuccess: isEdgeConfigSuccess || isContractStatusSuccess,
  };
};

export default useStatus;
