import Image from 'next/image';

import * as Dialog from '@radix-ui/react-dialog';
import { useDisconnect, useSignMessage } from 'wagmi';

import { ButtonV2, ComponentSize, TextV2 } from '@layr-labs/eigen-kit/react';

import useAccount from '@/hooks/useAccount';
import useTOS from '@/hooks/useTOS';

import tosHashes from 'config/tosHashes.json';
import { isValidSignature } from '@/utils/index';

import Privacy from './Privacy';
import RestrictedAccessModal from './RestrictedAccessModal';
import Terms from './Terms';

const TOS_MESSAGE = `By signing this message, you acknowledge that you have read and understood our Terms of Service and Privacy Policy. You agree to abide by all the terms and conditions contained in the hash: ${
  tosHashes[tosHashes.length - 1].hash
}`;

export default function TOSProvider({ children }) {
  const { access, isTermsSigned, setIsTermsSigned, cacheSignature, storeUserTerms } = useTOS();
  const { isConnected, address, addressAccess } = useAccount();
  const { disconnect } = useDisconnect();
  // NOTE: show TOS if not signed and is connected and not impersonating
  const showTOS = !isTermsSigned && isConnected;

  const showRestrictedAccessModal = !showTOS && addressAccess.data === 'restrict';

  const { isPending, signMessage } = useSignMessage({
    mutation: {
      onSuccess: async (data) => {
        const signatureObj = await isValidSignature(address!, TOS_MESSAGE, data);
        const userAgreement = {
          publicKey: signatureObj.pubKeyHex,
          signedTerms: {
            hash: signatureObj.messageHash,
            signature: data,
            timestamp: new Date().getTime(),
          },
        };
        cacheSignature({
          ...userAgreement,
          userAddress: address!,
        });
        storeUserTerms.mutate(userAgreement);
        setIsTermsSigned(true);
      },
    },
  });

  if (addressAccess.data === 'deny') {
    return (
      <div data-cypress="access" className="flex h-screen items-center justify-center">
        <div className="mx-10 flex flex-col items-center justify-center">
          <TextV2 intent="DisplayM" className="mb-4 text-center">
            ACCESS RESTRICTED
          </TextV2>
          <TextV2 intent="DisplayS" className="text-center">
            Your wallet has been flagged as being associated with certain high risk activity or
            jurisdictions. Functionality of the application has been disabled. Please visit our{' '}
            {'  '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.eigenlayer.xyz/eigenlayer/legal/terms-of-service"
              className="hover:text-interactivePrimaryFgHover text-blue-700 underline underline-offset-4"
            >
              Terms of Service
            </a>{' '}
            for more information.
          </TextV2>
          <TextV2 intent="DisplayS" className="text-center">
            If you feel your wallet has been denied access incorrectly, please reach out to our help
            channel.
          </TextV2>
          <Image
            src="/logo/horizontalDarkA.svg"
            height={160}
            className="mt-4"
            priority
            alt="eigenlayer logo"
          />
        </div>
      </div>
    );
  }

  if (!access) {
    return (
      <div data-cypress="access" className="flex h-screen w-screen items-center justify-center">
        {addressAccess.isLoading ? (
          <Image
            src="/eigenGif/White-Eigen-Loader.gif"
            height={24}
            width={24}
            alt="eigenlayer loader"
            className="mx-auto rounded-lg"
          />
        ) : (
          <TextV2>Access Denied</TextV2>
        )}
      </div>
    );
  }

  if (addressAccess.data === 'error') {
    return (
      <div
        data-cypress="access"
        className="flex h-screen w-screen flex-col items-center justify-center"
      >
        <TextV2>We are currently experiencing heavy traffic</TextV2>
        <TextV2>Please try again later</TextV2>
        <TextV2>Funds are safe</TextV2>
      </div>
    );
  }

  return (
    <>
      {children}
      <RestrictedAccessModal address={address} addressIsRestricted={showRestrictedAccessModal} />
      <Dialog.Root open={showTOS}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed top-0 z-10 flex h-screen w-screen items-center justify-center bg-black-200" />
          <Dialog.Content className="fixed left-1/2 top-1/3 z-[200] flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-4 rounded-lg bg-white-950 px-4 pb-6 pt-4 outline-none sm:mt-20 sm:h-auto sm:w-[400px]">
            <TextV2 intent="DisplayXS" className="text-center">
              TERMS OF SERVICE & PRIVACY POLICY
            </TextV2>
            <div className="text-tertiaryFg h-[300px] w-full overflow-y-scroll rounded-lg border border-black-100 p-4 text-TextS">
              <Terms />
              <Privacy />
            </div>
            {/* to prevent the first button from being focused on dialog open */}
            <button className="h-0 w-0 outline-none" />
            <ButtonV2
              onClick={() => signMessage({ message: TOS_MESSAGE })}
              className="flex w-full items-center justify-center"
              size={ComponentSize.MD}
            >
              {isPending ? (
                <Image
                  src="/eigenGif/White-Eigen-Loader.gif"
                  height={24}
                  width={24}
                  alt="eigenlayer loader"
                  className="rounded-lg"
                />
              ) : (
                'Sign'
              )}
            </ButtonV2>
            <ButtonV2
              onClick={() => {
                disconnect();
              }}
              size={ComponentSize.MD}
              intent="secondary"
              className="w-full"
            >
              Cancel
            </ButtonV2>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
