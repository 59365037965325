import { useMemo } from 'react';

import { Token } from '../classes/token';

export const useTVLs = (
  liquidTokens: Token[] | undefined,
  nativeRestakingToken: Token | undefined | null,
) => {
  const liquidTotalUSD =
    liquidTokens?.reduce((acc, { tvl, usd }) => {
      return usd ? acc + tvl * usd : acc;
    }, 0) ?? 0;
  const liquidTotalETH =
    liquidTokens?.reduce((acc, { tvl, ethValue }) => {
      return ethValue ? acc + tvl * ethValue : acc;
    }, 0) ?? 0;

  const nativeTVL = nativeRestakingToken?.tvl ?? 0;
  const nativeEthPrice = nativeRestakingToken?.usd ?? 0;

  const totalTVLData = useMemo(() => {
    return {
      totalUSD: liquidTotalUSD + nativeTVL * nativeEthPrice,
      totalETH: liquidTotalETH + nativeTVL,
    };
  }, [nativeTVL, nativeEthPrice, liquidTotalUSD, liquidTotalETH]);

  return {
    isLoading: !(liquidTokens && nativeRestakingToken && totalTVLData),
    isSuccess: liquidTokens && nativeRestakingToken && totalTVLData,
    data: totalTVLData,
  };
};
