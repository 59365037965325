import React, { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { Address } from 'abitype';
import { v4 as uuidv4 } from 'uuid';
import { useWriteContract } from 'wagmi';
import { waitForTransactionReceipt } from 'wagmi/actions';

import { useToast } from '@layr-labs/eigen-kit/react';
import { TransactionLifecycle } from '@layr-labs/eigen-kit/types';

import useTransactionStore from '@/hooks/store/transactionStore';

import { config } from 'chain/Web3Provider';

import { env } from '@env';

import type { WriteContractErrorType } from 'viem';

interface ContractWriteOptions extends TransactionLifecycle {
  loadingMsg?: React.ReactNode;
  successMsg?: React.ReactNode;
  errorMsg?: React.ReactNode;
  disableToast?: boolean;
}

export interface TxnContext {
  id: string;
}

const useWriteContractWithToast = ({
  loadingMsg = 'Transaction loading...',
  successMsg = 'Transaction successful',
  errorMsg = 'Transaction submitted.',
  onTxnSuccess,
  onTxnRevert,
  onTxnSettled,
  onTxnError,
  disableToast = false,
  onSuccess,
  onError,
  onSettled,
  onMutate,
}: Partial<ContractWriteOptions>) => {
  const toast = useToast();
  const addTransaction = useTransactionStore((state) => state.addTransaction);
  const removeTransaction = useTransactionStore((state) => state.removeTransaction);
  const updateTransaction = useTransactionStore((state) => state.updateTransaction);

  const writeContract = useWriteContract({
    mutation: {
      onMutate: (): TxnContext => {
        onMutate?.();
        const id = uuidv4();
        addTransaction({
          id,
          status: 'pending',
          loadingMsg,
          successMsg,
          errorMsg,
          disableToast,
        });
        return { id };
      },
      onError: (_error, _variables, { id }: TxnContext) => {
        // TODO: Add Error handling
        removeTransaction(id);
        onError?.(_error as WriteContractErrorType);
        toast.dismiss(id);
        datadogRum.addError(_error);
      },
      onSuccess: (hash, _variables, context: TxnContext) => {
        onSuccess?.(hash);
        const { id } = context;
        updateTransaction(id, { hash });

        waitForTransactionReceipt(config, { hash, timeout: 1000 * 60 * 5 })
          .then((txnReceipt) => {
            if (txnReceipt.status === 'success') {
              onTxnSuccess?.(txnReceipt);
              updateTransaction(id, {
                status: 'success',
              });
            } else if (txnReceipt.status === 'reverted') {
              onTxnRevert?.(txnReceipt);
              updateTransaction(id, {
                status: 'error',
              });
              datadogRum.addError(txnReceipt);
            }
            onTxnSettled?.(txnReceipt);
          })
          .catch((error) => {
            datadogRum.addError(error);
            updateTransaction(id, {
              status: 'error',
            });
            onTxnError?.();
          });
      },
      onSettled: () => {
        onSettled?.();
      },
    },
  });

  return writeContract;
};

function TxnToastMsg({ msg, hash }: { msg: React.ReactNode; hash: Address }) {
  return (
    <div className="flex flex-col">
      <div>{msg}</div>
      <div>
        View on{' '}
        <a
          href={`${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${hash}`}
          className="underline"
          target="_blank"
          rel="noreferrer"
        >
          Etherscan
        </a>
      </div>
    </div>
  );
}

export const useTransactionToast = () => {
  const toast = useToast();
  const transactions = useTransactionStore((state) => state.transactions);
  const updateTransaction = useTransactionStore((state) => state.updateTransaction);
  const removeTransaction = useTransactionStore((state) => state.removeTransaction);

  useEffect(() => {
    transactions.forEach((txn) => {
      const disableToast = txn.disableToast;
      if (txn.status === 'pending' && !txn.toastId) {
        if (!disableToast) {
          // Trigger a loading toast using the transaction id as the toastId
          const toastId = toast.loading(txn.loadingMsg as string, { id: txn.id }, false);
          updateTransaction(txn.id, { toastId }); // Store the toastId in the transaction
        }
      } else if (txn.status === 'pending' && txn.toastId && txn.hash) {
        // Update the toast with the transaction hash
        if (!disableToast) {
          toast.loading(
            <TxnToastMsg msg={txn.loadingMsg} hash={txn.hash} />,
            {
              id: txn.toastId,
            },
            false,
          );
        }
      } else if (txn.status === 'success' && txn.toastId && txn.hash) {
        if (!disableToast) {
          toast.success(<TxnToastMsg msg={txn.successMsg} hash={txn.hash} />, {
            id: txn.toastId,
          });
        }
        updateTransaction(txn.id, { toastId: undefined });
        removeTransaction(txn.id);
      } else if (txn.status === 'error' && txn.toastId && txn.hash) {
        if (!disableToast) {
          toast.info(<TxnToastMsg msg={txn.errorMsg} hash={txn.hash} />, {
            id: txn.toastId,
          });
        }
        updateTransaction(txn.id, { toastId: undefined });
        removeTransaction(txn.id);
      }
    });
  }, [transactions, updateTransaction, removeTransaction, toast]);
};

export default useWriteContractWithToast;
